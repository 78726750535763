// based in part on this SO answer: https://codereview.stackexchange.com/a/211511

export default function PlaintextLine({ text }) {
  return (
    <>
      <br />
      <span>{text}</span>
    </>
  );
}
